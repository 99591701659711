@tailwind base;
@tailwind components;
@tailwind utilities;

#__next {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

/* custom scrollbar for the app */

*::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background-clip: padding-box;
}
*::-webkit-scrollbar-thumb {
  width: 10px;
  border-radius: 13px;
  border: 2px solid transparent;
  box-shadow: none;
}

*:hover::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 0 10px rgba(0, 0, 0, 0.4);
}

.dark *:hover::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 0 10px rgba(255, 255, 255, 0.4);
}

@layer utilities {
  .overflow-initial {
    overflow: initial;
  }
  .overflow-auto {
    overflow: overlay;
  }

  .overflow-scroll {
    overflow: overlay;
  }

  .overflow-x-auto {
    overflow-x: overlay;
  }

  .overflow-y-auto {
    overflow-y: overlay;
  }

  .overflow-x-scroll {
    overflow-x: overlay;
  }

  .overflow-y-scroll {
    overflow-y: overlay;
  }
}

:root {
  --toastify-color-light: hsl(var(--b1));
  --toastify-color-dark: hsl(var(--b1));
  --toastify-color-info: hsl(var(--in));
  --toastify-color-success: ;
  --toastify-color-warning: hsl(var(--wa));
  --toastify-color-error: hsl(var(--er));
  --toastify-color-transparent: rgba(255, 255, 255, 0.7);
  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);
  --toastify-toast-width: 320px;
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: sans-serif;
  --toastify-z-index: 9999;
  --toastify-text-color-light: hsl(var(--bc));
  --toastify-text-color-dark: hsl(var(--bc));
  --toastify-text-color-info: hsl(var(--inc));
  --toastify-text-color-success: hsl(var(--suc));
  --toastify-text-color-warning: hsl(var(--wac));
  --toastify-text-color-error: hsl(var(--errc));
  --toastify-spinner-color: #616161;
  --toastify-spinner-color-empty-area: #e0e0e0;
  --toastify-color-progress-light: linear-gradient(
    to right,
    #4cd964,
    #5ac8fa,
    #007aff,
    #34aadc,
    #5856d6,
    #ff2d55
  );
  --toastify-color-progress-dark: #bb86fc;
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);
}
